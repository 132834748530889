html, body, #root, .App{min-width:100vw; height:100vh; display:flex; align-items:center; justify-content:center; position:relative;; background-color:black; overflow:hidden;}
.logo{width:100vw; height:100vh; display:flex; align-items:center; justify-content:center; position:absolute; top:0; left:0; z-index:1;}

.blobs *{
	position:absolute;
	top:0;
	left:0;
	bottom:0;
	right:0;
	animation:move 60s infinite alternate linear;
}

.blob1{offset-path:path('M1059.16 -376.5C1566.32 -70.3333 2422.66 1098.5 1720.66 1464.5C843.156 1922 588.656 -394 1720.66 182C2211.23 431.622 729.656 2048 390.156 984C118.556 132.8 274.656 -36.6274 702.156 -19.5C1213.14 0.972107 994.556 877.8 224.156 1057C-546.244 1236.2 -264.511 296.667 -27.344 -195.5');}
.blob2{offset-path:path('M1059.16 -376.5C1566.32 1123.33 1132.16 237 619.158 498.5C-1298.46 1476.01 2095.66 1160 1901.66 370.501C1700.16 -449.52 -17.3416 557 50.6584 38.5C163.077 -818.689 1152.16 1663 2176.16 -545');}
.blob3{offset-path:path('M1059.16 -178C501.012 -107.341 -315.953 659.102 453.154 1044.5C1420.04 1529 2479.03 472.191 2002.15 154C1420.84 -233.877 125.637 812.21 -204.656 1552.5');}

@keyframes move{
	0%{
		offset-distance: 0%;
	}
	100%{
		offset-distance: 100%;
	}
}

@media screen and (max-width:575px) and (min-width:0){
	.logo img{width:calc(100% - 100px); height:auto; margin:auto;}
}
